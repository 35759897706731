import {
  UnitOfMeasurementCode,
  UserPreferences,
} from '../types/UserPreferences';

export const DEFAULT_USER_PREFERENCES: UserPreferences = {
  currency: 'EUR',
  countryCode: 'NL',
  unitOfMeasurement: 'CM',
  cookieConsent: 'UNDECIDED',
};

export const UNIT_OF_MEASUREMENT_CODES: UnitOfMeasurementCode[] = ['CM', 'IN'];

export const CURRENCY_DESCRIPTIONS: Record<CurrencyCode, string> = {
  'EUR': '(EUR €)',
  'GBP': '(GBP £)',
  'USD': '(USD $)',
} as const;

export const CURRENCY_SYMBOLS: Record<CurrencyCode, string> = {
  'EUR': '€',
  'GBP': '£',
  'USD': '$',
} as const;

export const UNIT_OF_MEASUREMENT_DESCRIPTIONS: Record<
  UnitOfMeasurementCode,
  string
> = {
  'CM': 'cm',
  'IN': 'in',
} as const;
